<template>
  <v-app>
    <v-main class="bg overlay">
      <v-container class="fill-height justify-center">
        <div>
          <v-row class="justify-center">
            <span class="white--text text-h4 font-weight-bold">CPA Web問題集</span>
          </v-row>
          <v-row class="my-3">
            <v-divider class="red mx-10" />
          </v-row>
          <v-row class="justify-center">
            <span class="white--text text-caption">以下よりログインをお願いします。</span>
          </v-row>
          <v-row class="justify-center mt-6">
            <v-btn
              color="#BA1C1F"
              rounded="rounded"
              dark="dark"
              @click="login"
            >
              ログイン
            </v-btn>
          </v-row>
        </div>
        <div class="custom-annotation mx-auto mb-3 px-10">
          <v-row class="justify-center mb-3">
            <v-btn
              class="opaque-button px-6"
              plain="plain"
              color="primary"
              @click="openWhatsNew = true"
            >
              <div class="flex flex-col">
                <span class="line-h-8">お知らせ</span>
                <div class="text--white line-h-8 small-text">
                  <i class="mdi mdi-update mr-1" />2025/03/03
                </div>
              </div>
            </v-btn>
            <cpa-whats-new :open-whats-new.sync="openWhatsNew" />
          </v-row>
        </div>
      </v-container>
    </v-main>
    <v-snackbar
      v-model="expired"
      bottom="bottom"
      color="error"
    >
      受講期限が切れています
      <template v-slot:action="{ attrs }">
        <v-icon
          v-bind="attrs"
          dark="dark"
          @click="expired = false"
        >
          mdi-close
        </v-icon>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>

import CpaWhatsNew from "@/components/layout/CpaWhatsNew.vue";

export default {
  name: "CpaTop",
  components: { CpaWhatsNew },
  data() {
    return {
      openWhatsNew: false,
      expired: false,
    }
  },
  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    this.expired = urlParams.get('expired') === 'true';
  },
  methods: {
    async login() {
      await this.$auth.loginWithRedirect();
    },
  },
}
</script>

<style scoped>
.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
  background-image: url("~@/assets/macbook.jpeg");
}

.overlay::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.custom-annotation {
  position: fixed;
  bottom: 10px;
}

.opaque-button {
  opacity: 0.9;
}

.line-h-8 {
  line-height: 0.9;
}

.small-text {
  font-size: 0.6rem;
}

</style>
